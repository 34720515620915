* {
  margin: 0; 
  padding: 0;
  scroll-behavior: smooth; 
  font-family: 'Poppins', sans-serif!important;
}
  
/* 
  Headings/Sub-Headings/Paragraphs/ Fonts Standard Spacing Fonts along with media queries,
*/

.font-mulish
{
  font-family: 'Mulish', sans-serif!important;
 
}
.font-lato
{
  font-family: 'Lato', sans-serif!important;
 
}
.font-Open-Sans
{
  font-family: 'Open Sans', sans-serif!important;
 
}

.standard-margin-space {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.standard-padding-space {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.body-heading, .hero-section-heading { 
  font-weight: 600;
  text-transform: uppercase;
}
.body-heading span, .hero-section-heading span{ 
  font-weight: 600;
  text-transform: uppercase;
}

.body-heading {
   color: #303030; 
   font-size: 49px; 
   font-weight: 600;}

.body-heading-privacy {
   color: #303030; 
   font-size: 35px; 
   font-weight: 600;}

   .sub{
    color: #303030; 
    font-size: 29px; 
    font-weight: 600;
   }

   .sub-link{
    font-size: 18px;
    color:  rgb(133,50,154);
   }

.hero-section-heading  
{ 
  font-size: 38px;
  font-weight: 600;
  color: white; 
  /* letter-spacing: 4px;  */
  font-family:'Poppins', sans-serif; 
}
.hero-section-heading span ,
.heading-color-change {
  font-size: 38px;
  font-weight: 600;
  color: rgb(133,50,154); 
   font-family:'Poppins', sans-serif;
  }
  
.body-paragraph { 
  font-weight: 400;
  font-size: 16px;
  color: #515151;
}

  

.genral-btn{ 
  padding: 0.5rem 2rem;
  font-size: 16px;
  background: rgb(133,50,154);
  background: linear-gradient(90deg, rgba(133,50,154,1) 0%, rgba(140,94,190,1) 100%);
  font-weight: 400;
  color: white;
  border-radius: 20px;
  border: none;
  transition: .3s;
}
.genral-btn:hover{ 
  background: linear-gradient(90deg, rgba(133,50,154,1) 30%, rgba(140,94,190,1) 100%);
}

.hero-sub-heading
{
  font-size: 1.5rem;
  font-weight: normal;
  color: white;
}
.sub-heading
{
  font-size: 25px;
  font-weight: 500;
  color: #8740A5;
  text-transform: uppercase;

}

.genral-btn span {
  margin-right: .4rem;
}

@media (max-width: 1250px) {
  .body-heading  {
    font-size: 37px;
  }
  .body-heading-privacy{
    font-size: 27px;
  }
  .sub{
    font-size: 20px;
  }

  .hero-section-heading {
    font-size: 50px;
  }
}

@media (max-width: 460px) {
  .body-heading  {
    font-size: 30px;
  }
  .body-heading-privacy{
    font-size: 20px;
  }
  .sub{
    font-size: 19px;
  }
  .hero-section-heading  {
    font-size: 35px;
  }

  .body-paragraph {
    font-size: 15px;
  }
}

@media (max-width: 380px) {
  .body-heading  {
    font-size: 27px;
  }

  .hero-section-heading  {
    font-size: 30px;
  }

  .body-paragraph {
    font-size: 14px;
  }

  .sub-heading {  
    /* font-size: 12px;  */
  } 
}


/* Width, color, and radius for the track */
::-webkit-scrollbar {
  width: 12px;
}

::-moz-scrollbar {
  width: 12px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #8740A5;
  transition: .3s;
  border-radius: 3px;
}

::-moz-scrollbar-thumb {
  background: #8740A5;
  transition: .3s;
  border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #753790;
}

::-moz-scrollbar-thumb:hover {
  background: #753790;
}

/* Track */
::-webkit-scrollbar-track {
  transition: .3s;
  background: #c3a8ce;
}

::-moz-scrollbar-track {
  transition: .3s;
  background: #c3a8ce;
}
/* Track on hover */
::-webkit-scrollbar-track:hover {
  transition: .3s;
  background: #c3a8ce;
}

::-moz-scrollbar-track:hover {
  transition: .3s;
  background: #c3a8ce;
}

/* Handle when dragging */
::-webkit-scrollbar-thumb:active {
  background: #8740A5;
}

::-moz-scrollbar-thumb:active {
  background: #8740A5;
}

/* Track when dragging */
::-webkit-scrollbar-track:active {
  transition: .3s;
  background: #c3a8ce;
}

::-moz-scrollbar-track:active {
  transition: .3s;
  background: #c3a8ce;
}
