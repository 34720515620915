body{
    background-color: white;
}
p,h1,h2,h3,h4,h5,h6,a,button,img,small
{
    user-select: none;
}

.left-gradient
{
    background-repeat: no-repeat!important;
    background: radial-gradient(circle at 20% center, rgb(243, 231, 236) 0%, rgba(255, 255, 255, 0.9) 50%, rgba(255, 255, 255, 0.5) 100%);
    background-size:45% 100%;
}
@media(max-width:992px)
{
    .left-gradient
    {
        
        background-size:100%;
    }
}

.right-gradient
{
    background-repeat: no-repeat!important;
    background: radial-gradient(circle at 100% 50%, rgb(243, 231, 236) 0%, rgba(255, 255, 255, 0.8) 70%, rgba(255, 255, 255, 0) 100%);
    background-size:55% 100%;
    background-position:right center;
}

@media(max-width:992px)
{
    .right-gradient
    {
        
        background-size:100%;
    }
}
.bottom-gradient
{
    background-repeat: no-repeat!important;
    background: radial-gradient(circle at left bottom, rgb(246, 228, 235) 0%, rgba(255, 255, 255, 0.8) 56%, rgba(242, 232, 245, 0.05) 93%, rgba(255, 255, 255, 0) 100%);
    background-size:100% 100%;
    background-position:left bottom;
}

@media(max-width:992px)
{
    .bottom-gradient
    {
        
        background-size:100%;
    }
}

