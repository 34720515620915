.Testimonials-container
{
    background: rgb(252,243,255);
    background: linear-gradient(90deg, rgba(252,243,255,1) 0%, rgba(243,243,243,1) 100%);
    width: 90%;
    margin: auto;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Testimonials-container .testimonial-card
{
    cursor: pointer;
    background-color: white;
    border-radius: 25px;
}
.Testimonials-container .testimonial-card .arrow-img
{
    width:45px;
}


.Testimonials-container .testimonial-card .testimonial-card-info
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}


.Testimonials-container .profile-info
{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.Testimonials-container .user-img{
    border-radius: 50%;
    width: 85px;
    transform: translateY(-30px);
    filter: drop-shadow(0px 3px 9px rgba(0,0,0,0.16));
}

.Testimonials-container .profile-info .review-text
{
    color: #515151;
    font-size: 14px;
    margin-bottom: 30px;
    line-height: 1.8rem;
    max-width: 80%;

}
.Testimonials-container .testimonial-card-line-seprator
{
    border: 1px solid #c8c8c8;
    width: 100%;
    height: 0;
    
}
.Testimonials-container .profile-info .name
{
    color: #141414;
    font-size: 1.2rem;
    font-weight: 600;
    margin-top: 10px;
}

.Testimonials-container .btn-container .btn-arrow-icon
{
    width: 14px;
    height: 14px;
}
.Testimonials-container .btn-container .btn-arrow-icon-left
{
    transform: rotate(130deg);
    transition:transform .3s ;
}
.Testimonials-container .btn-container .btn-arrow-icon-left-rotate
{
    transition:transform .3s ;
    transform: rotate(180deg);
}

.Testimonials-container .btn-container
{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
}
.Testimonials-container .btn-container .swiper-button
{
    border-radius: 50%;
    border: none;
    background-color: white;
    border: 1px solid white;
    padding: 10px 20px;
    color: purple;
    box-shadow:0 3px 8px 0 RGBA(0,0,0,0.16 );
    transition: .2s;
}
.Testimonials-container .btn-container .swiper-button:hover
{
    border: 1px solid #8740A5;
    box-shadow:0 0px 0px 0 RGBA(0,0,0,0.16 );
}


.Testimonials-container .swiper-slide
{
    transform: translate(50% , 25px);
    overflow-y: visible;
    display: block;
    
}
.Testimonials-container .swiper-slide-active
{
    transform: translate(-50% , 25px);
    display: block;
}
.Testimonials-container .swiper-slide-next
{
    transform: translate(0 , 0)!important;
    display: grid;
    align-items: center;
    justify-content: center;
}
.Testimonials-container .swiper-slide-next .testimonial-card
{
    width: 600px;
}

@media(max-width:1450px)
{
    .Testimonials-container .swiper-slide-active
    {
        transform: translate(0 , 0)!important;
    }
    .Testimonials-container .swiper-slide-next
    {
        transform: translate(0 , 0)!important;
    }
    .Testimonials-container .swiper-slide
    {
        transform: translate(0 , 0)!important;
    }
    .Testimonials-container .swiper-slide-next .testimonial-card
    {
        width: auto;
    }
    .Testimonials-container .swiper-slide .testimonial-card
    {
        width: 80%;
        margin: auto;
    }
    .Testimonials-container .swiper{
        padding-left: 10px;
        padding-right: 10px;
    }
}

@media (min-width: 1850px) {

    .Testimonials-container .swiper-slide
    {
        /* transform: translate(40% , 25px); */
        overflow-y: visible;
        display: block;
        display: flex;
        align-items: center;
        justify-content: center;
        
    }
    .Testimonials-container .swiper-slide-active
    {
        /* transform: translate(-50% , 25px); */
        display: block;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .Testimonials-container .swiper-slide-next
    {
        transform: translate(0 , 0)!important;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .Testimonials-container .swiper-slide-next .testimonial-card,
    .Testimonials-container .swiper-slide-active .testimonial-card,
    .Testimonials-container .swiper-slide .testimonial-card
    {
        width: 800px;
        margin: 0 -50%;
    }
}

@media(max-width: 750px)
{
    .Testimonials-container .swiper-slide .testimonial-card
    {
        width: 95%;
        margin: auto;
    }
}
@media(max-width: 576px)
{
    .Testimonials-container .swiper-slide .testimonial-card
    {
        width: 100%;
        margin: auto;
    }
}