.women-appointment-container
{
    background-image: url("../../../public/images/creatives/women-book-appointment-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 30px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}

.women-appointment-container .text-content
{
    color: white;
}

.women-appointment-container .text-content .title
{
    color: white;
    font-size: 2.3rem;
    font-weight: 600;
    
}
.women-appointment-container .text-content .small-title
{
    color: white;
    font-size: 1.7rem;
    font-weight: normal;
    
}

.women-appointment-container .text-content .body-paragraph
{
    line-height: 1.9rem;
}

.women-appointment-container .text-content .content-btn
{
  padding: 0.6rem 2rem;
  font-size: 14px;
  background: transparent;
  border: 1px solid white;
  font-weight: 400;
  color: white;
  font-weight: 700;
  letter-spacing: 1px;
  border-radius: 20px;
  font-family: "Open Sans";
  transition: .3s;
}
.women-appointment-container .text-content .content-btn:hover
{
  background: white;
  border: 1px solid white;
  color: #8740A5;
}

@media(max-width : 400px)
{
    .women-appointment-container .text-content .content-btn
    {
        padding: 0.6rem 1.5rem;
        font-size: 14px;
    }
}