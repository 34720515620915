.BgTextContent-container
{
    background-image: url("../../../public/images/creatives/payment-bg-content.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.BgTextContent-container .content-btn
{
  padding: 0.6rem 2rem;
  font-size: 14px;
  background: transparent;
  border: 1px solid white;
  font-weight: 400;
  color: white;
  font-weight: 700;
  letter-spacing: 1px;
  border-radius: 20px;
  font-family: "Open Sans";
  transition: .3s;
}
.BgTextContent-container .content-btn:hover
{
  background: white;
  border: 1px solid white;
  color: #8740A5;
}