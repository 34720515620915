.main h2,
.main p {
  text-align: start;
  align-self: flex-start;
}
.main p {
  color: rgb(35, 35, 35);
}
.main h2 {
  color: #ec1d23;
  font-weight: 500;
}
.footer {
  width: 100%;
  padding: 25px 0px 25px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgb(145, 145, 145);
}
.footer p {
  align-self: flex-end;
}
.footer p span {
  color: rgb(145, 145, 145);
}
.socials {
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: row;
  gap: 10px;
}
.para_main img
{
  margin: auto!important;
}
.para_main a
{
 color: #C5A6E2!important;
}
.socials a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 38px;
  height: 38px;
  cursor: pointer;
  border-radius: 50%;
  background-color: #e4e4e4;
  fill: #ec1d23;
  transition: 0.2s ease;
}
.socials svg {
  width: 17px;
  height: 17px;
}
.socials a:hover {
  background-color: red;
}
.socials a:hover svg {
  fill: #fff;
}
