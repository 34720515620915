.text-card-container
{
    background: transparent;
}
.text-card-container .container-width
{
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}

.text-card-container .text-card-box
{
    border-radius: 9px;
    filter: drop-shadow(0px 3px 9px rgba(0,0,0,0.16));
    background-color: #ffffff;
    transition: height 1s ease-in-out;
}

.text-card-container .text-card-box .title
{
    font-size: 28px;
    font-weight: 600;
    color: #8740a5;
}
.text-card-container .text-card-box
{
    transition:height .5s;
}
.text-card-container .text-card-box .open
{
    animation: bottomBoxOpenCard .6s ease-in-out;
}

@keyframes bottomBoxOpenCard {
    from{
        opacity: 0.2;
        transform: translateY(-3px);
    }
    to{
        opacity: 1;
       transform: translateY(0px);
    }
}
.text-card-container .text-card-box .close
{
    animation: bottomBoxCloseCard .3s ease-in-out;
}

@keyframes bottomBoxCloseCard {
    from{
        transform: translateY(3px);
    }
    to{
       transform: translateY(0px);
    }
}
