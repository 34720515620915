.map-container
{
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}
.map-container .iframe-map
{
    width: 100%;
    height: 230px;
    border-radius: 10px;
}