.pdf-list-container
{

}

.pdf-list-container .container-width
{
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}

.pdf-list-container .pdf-card-box
{
    border-radius: 9px;
    filter: drop-shadow(0px 3px 9px rgba(0,0,0,0.16));
    background-color: #ffffff; 
    width: 90%;

}

.pdf-list-container .pdf-card-box .anchor-text
{
    font-size: 20px;
    text-decoration: underline;
    color: #8740a5;
    font-weight: 500;
}

.pdf-list-container .pdf-card-box .pdf-icon
{
    width:25%;
    transform:translateX(-23%);
}

@media(max-width:992px)
{
    .pdf-list-container .pdf-card-box .pdf-icon
    {
        width:150px;
        margin: auto;
        margin-top: 10px;
        margin-bottom: 10px;
        transform:translateX(0);
    }
}