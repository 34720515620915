.how-text-container
{
    background: #ecdbf0;
    background: linear-gradient(141deg, #ecdbf0 0.00%, #f9f2fb 100.00%);
    width: 90%;
    margin-right: auto;
    margin-left: auto;
    border-radius: 30px;
}

.how-text-container .text-content
{
    background-image: url("../../../public/images/icons/how-it-work-bg.png");
    background-size: 15%; 
    background-position: top left; 
    background-repeat: no-repeat;
}

.how-text-container .text-content .title
{
    color: #8740A5;
    font-size: 2.3rem;
    font-weight: 600;
}
.how-text-container .text-content .body-paragraph
{
    line-height: 1.9rem;
}

@media(max-width:1200px)
{
    .how-text-container .text-content
    {
         background-size: 20%; 
    }
}
@media(max-width:768px)
{
    .how-text-container .text-content
    {
         background-size: 30%; 
    }
}