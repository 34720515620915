.common_hero_section {
  color: black;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
 
  width: 100%;
  min-height: 40rem;
  /* height: 40rem; */
  z-index: 0;
}

.common_hero_section .bg-effect
{
  background: rgb(26, 26, 26);
  position: relative;
  z-index: -1;
  background: linear-gradient(0deg, rgba(40, 40, 40, 0.585) 0%, rgba(65, 65, 65, 0.543) 100%);
}

.common_hero_section .hero-container
{
  width: 90%;
  margin: auto;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.common_hero_section .cus-head-font
{
  font-family: 'Raleway', sans-serif!important;
}

.common_hero_section .wrapper
{
  height: 100%;
}

.bg-video
{
  height: 100vh;
  min-height: 500px;
}

.hero-bg-video{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.openModalBtn {
  background-image: linear-gradient(to right, #8026a6, #c07cd0);
  color: #fff; /* Text color */
  padding: 12px 24px; /* Padding */
  font-size: 16px; /* Font size */
  border: none; /* Remove border */
  border-radius: 10px; /* Rounded corners */
  cursor: pointer; /* Cursor on hover */
  transition: background-color 0.3s ease; /* Smooth transition */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Add shadow for depth */
}

.openModalBtn:hover {
  background-color: #448aff; /* Change color on hover */
}


.common_hero_section .hero_content_wrapper {
  display: flex;
  padding-left: 0%;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  width: 85%;
}

.common_hero_section .hero_content_wrapper .hero-section-heading,
.common_hero_section .hero_content_wrapper .body-paragraph {
  text-align: center;
}

.common_hero_section .hero_content_wrapper .body-paragraph
{
  width: 80%;
  max-width: 850px;
  color: white;
}

.common_hero_section .hero_content_wrapper .hero-btn
{
  padding: 0.6rem 2.3rem;
  font-size: 14px;
  background: transparent;
  border: 1px solid white;
  font-weight: 700;
  color: white;
  font-weight: bold;
  letter-spacing: 1px;
  border-radius: 29px;
  transition: .3s;
}
.common_hero_section .hero_content_wrapper .hero-btn:hover
{
  background: white;
  border: 1px solid white;
  color: #8740A5;
}

.common_hero_section .service-form
{
  background-color: white;
  border-radius: 10px;
}
.common_hero_section .service-form .service-from-btn
{
  width: fit-content;
  margin-top: 25px;
}
.common_hero_section .service-form input
{
  border: none;
  border-bottom: 1px solid rgb(0, 0, 0);
  background:transparent;
  margin-top: 20px;

}
.common_hero_section .service-form input:focus
{
  outline: none;
}

.common_hero_section .hero-text-content .body-paragraph 
{
  /* width: 50%; */
  text-align: center;
}


@media(max-width : 660px)
{
  .common_hero_section .hero-text-content .body-paragraph 
  {
    width : 100%;
  }
}

@media(max-width : 400px)
{
  .common_hero_section .hero_content_wrapper .hero-btn
  {
    padding: 0.6rem 1.8rem;
    font-size: 15px;
  }
}