.service-text-container
{
}
.service-text-container .container-width
{
    width: 90%;
    margin: auto;
    background: linear-gradient(107deg, #fcf3ff 0%, #f3f3f3 100%);
    border-radius: 29px;
}
.service-text-container .container
{
    background-color: #FCF3FF;
    border-radius: 30px;
}

.service-text-container .text-content
{

}

.service-text-container .card-box 
{
    background-color: white;
    height: 215px;
    border-radius: 20px;
}

.service-text-container .card-box .card-img
{
    width: 240px;
    height: 352px;
}

.service-text-container .card-container
{
    height: 360px;
}

.service-text-container .services-card-text
{
    text-align: center;
    
}

.service-text-container .services-card-text .services-anchor
{
    cursor: pointer;
    font-size: 16px;
    text-decoration: none;
    
    color: #8740a5;
    transition: .3s;
}
.service-text-container .services-card-text .services-anchor:hover
{
    cursor: pointer;
    font-weight: 500;
    color: #5c0e7e;
}
.service-text-container .services-card-text .title
{
    font-size: 1.4rem;
    font-weight: 500;
    color: #303030;
}

@media(max-width : 1180px)
{
    .service-text-container .card-container
    {
        height: 765px;
        padding-top: 200px;
        flex-direction: column!important;
        justify-content: space-between!important;
    }
    
}

@media(max-width:650px)
{
    .service-text-container .card-box 
    {
        height: auto;
        gap: 20px;
        width: 90%;
        margin: auto;
        flex-direction: column!important;
        align-items: center!important;
    }
    .service-text-container #column-rev 
    {
        flex-direction: column-reverse!important;
    }
    .service-text-container .card-box 
    {
        height: auto;
        gap: 20px;
        width: 90%;
        margin: auto;
        padding-top: 2%!important;
        /* padding-bottom: 30px!important; */
        flex-direction: column!important;
        align-items: center!important;
    }
    .service-text-container .card-container
    {
        height: auto;
        padding-top: 0;
        gap: 40px!important;
    }
    .service-text-container .card-container .line-img
    {
        display: none;
    }
    .service-text-container .services-card-text
    {
        padding-top: 20px!important;
        padding-bottom: 20px!important;
    }
}

@media(min-width:1500px)
{
    .service-text-container .services-card-text
    {
        width: 372px;
    }
}