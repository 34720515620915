.ScrollingTextContent-container
{
}

/* .ScrollingTextContent-container .genral-btn
{
    background-color: #203354;
    color: white;
    transition: .3s;
} */
/* .ScrollingTextContent-container .btn-y
{
    background-color: #E1A92A!important;
    color: white;
    transition: .3s;
} */

.ScrollingTextContent-container .text-btn
{
  padding: 0.6rem 2.2rem;
  font-size: 14px;
  background: transparent;
  border: 1px solid #8740A5;
  color: #8740A5;
  font-weight: 700;
  font-family: "Open Sans";
  border-radius: 25px;
  text-transform: uppercase;
  transition: .3s;
}
.ScrollingTextContent-container .text-btn:hover
{
  background: #8740A5;
  border: 1px solid #8740A5;
  color: white;
}

.ScrollingTextContent-container .text-content .content
{
    color: #454545;
}

.ScrollingTextContent-container .scroll-content
{
    height: 300px;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    overflow-y: scroll;
}