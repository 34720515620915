.footer-container
{
    background-color: #141414;
    padding-top: 180px;
    color: white;
}

.footer-container .line-seprator
{
    border: 1px solid rgba(255, 255, 255, 0.457);
    width: 90%;
    margin: auto;
    margin-top:50px;
}

/* Upper container */

.footer-container .upper-newsletter-container .page-links .anchor
{
    text-decoration: none;
    color: white;
    cursor: pointer;
}
.footer-container .upper-newsletter-container .page-links .active
{
    color: #8740A5;
}

.footer-container .lower-container .social-link{
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 10px;
}


.footer-container .upper-newsletter-container .newsletter-form
{
    display: flex;
    align-items: center;
}
.footer-container .upper-newsletter-container .newsletter-form input
{
    border: none;
    background: transparent;
    border: 1px solid #02A1A9;
    border-radius: 30px;
    padding:15px 15px;
    color: white;
    width:25em;
}
.footer-container .upper-newsletter-container .newsletter-form input:focus
{
    outline: none;
}
.footer-container .upper-newsletter-container .newsletter-form button
{
    display: flex;
    align-items: center;
    gap: 10px;
    border: none;
    position: relative;
    right: 15%;
    border-radius: 30px;
    background-color: #02A1A9;
    color: white;
    padding:14px 15px;
    font-weight: 700;
    font-size: 1.25rem;
}

.footer-container .upper-newsletter-container .newsletter-form button img{
    width: 15px;
}

.footer-container .upper-newsletter-container .newsletter-form button:hover{
    cursor: pointer;
    background-color: #1898d8;
}
.footer-container .upper-newsletter-container .info-container .loc-heading
{
    font-size: 18px;
    font-weight: bold;
}
.footer-container .upper-newsletter-container .info-container .info-content
{
    font-size: 16px;
    font-weight: normal;
}

.footer-container .upper-newsletter-container .text-container .text-content p
{
    font-size: 16px;
}
.footer-container .upper-newsletter-container .text-container .text-content h3
{
    margin-bottom: 5px;
    font-weight: 700;
    font-size: 1.3rem;
    color: white;
}
.footer-container .lower-container .social-link img{
    width: 25px;
    height: 25px;
}

/* Lower Container */

.footer-container .lower-section 
{
    background-color: white;
    color: #6b6a6a;
}
.footer-container .lower-container
{
    display: flex;
    align-items: center;
    text-align: center;
    color: #484747;
    background-color: white;
}
.footer-container .lower-container p
{
   font-size: .8rem;
}
.footer-container .lower-container p a
{
   font-size: .8rem;
   color: #484747;
   text-decoration: none;
}
.footer-container .lower-container .social-icon
{
    border-radius: 50%;
    transition: .2s;
}
.footer-container .lower-container .social-icon:hover
{
    cursor: pointer;
    border:2px solid #6F2A8B;
}

.form .form-container
{
    width: 90%;
    background: rgb(149,120,159);
    background: linear-gradient(90deg, rgba(149,120,159,1) 0%, rgba(110,40,139,1) 100%);
    border-radius: 12px;
    box-shadow: 0px 3px 18px rgba(0,0,0,0.16 );
    padding: 70px 1px;
    transform: translateY(120px);
}

.form .form-container .inp-container
{
    background:transparent;
    border: 1px solid white;
    padding-left:5px;
    border-radius: 5px;
    width:65%;
    margin: auto;
    margin-top: 40px;
    overflow: hidden;
}
.form .form-container .inp-container input{
    width:89%;
    border: none;
    padding: 10px;
    padding-left: 15px;
    color: white;
    border-radius: 5px;
    background:transparent;

}
.form .form-container .inp-container input::placeholder{
    color: white;
}
.form .form-container .inp-container input:focus{
    outline: none;
}
.form .form-container .inp-container .inp-btn
{
    padding: 10px 10px;
    border: none;
    width: 100px;
    border-radius: 5px;
    color: #6F2A8B;
    background-color: white;
    transition: .3s;
}
.form .form-container .inp-container .inp-btn:hover
{
    color: white;
    background-color: #6f2a8b3e;
}
.form .form-container .inp-container-resp
{
    background:transparent;
    padding-left:5px;
    border-radius: 5px;
    width:80%;
    margin: auto;
    margin-top: 40px;
    overflow: hidden;
}
.form .form-container .inp-container-resp input{
    width:100%;
    border: 1px solid white;
    padding: 10px;
    padding-left: 15px;
    color: white;
    border-radius: 5px;
    background:transparent;
}
.form .form-container .inp-container-resp input::placeholder{
    color: white;
}
.form .form-container .inp-container-resp input:focus{
    outline: none;
}
.form .form-container .inp-container-resp .inp-btn
{
    padding: 10px 10px;
    margin-top: 20px;
    border: none;
    width: 100px;
    border-radius: 5px;
    color: #6F2A8B;
    background-color: white;
    transition: .3s;
}
.form .form-container .inp-container-resp .inp-btn:hover
{
    color: white;
    background-color: #6f2a8b3e;
}

@media(max-width : 1050px)
{
    .footer-container .upper-newsletter-container
    {
        flex-direction: column;
    }
    .footer-container .upper-newsletter-container .text-container
    {
        flex-direction: column;
    }
    .footer-container .upper-newsletter-container .text-container,.newsletter-form
    {
        margin: auto;
        margin-bottom: 15px;
    }
    .footer-container .upper-newsletter-container .newsletter-form button
    {
        right: 50px;
    }
    
}

@media (max-width: 730px) {
    
    .footer-container .upper-newsletter-container .newsletter-form
    {
        justify-content: center;
        width: 70%;
        position: relative;
    }
    .footer-container .upper-newsletter-container .newsletter-form input
    {
        width: 100%;
        padding: 7px 10px;
        margin: auto;

    }
    .footer-container .upper-newsletter-container .newsletter-form button
    {
        padding:5px 10px;
        position: absolute;
        right:0;
    }
    .footer-container .lower-container
    {
        padding:10px 5px;
        text-align: center;
    }
    
}

@media(max-width : 768px)
{
    .footer-container .upper-newsletter-container .text-container{
        
        text-align: center;
    }
    .footer-container .lower-container .social-link{
        justify-content: center;
    }
    .footer-container .line-seprator
    {
        margin-top: 20px;
    }
    .footer-container .lower-container p
    {
        font-size: .6rem;
    }
}

@media(max-width:498px)
{
    .form .form-container .inp-container input{
        width:53%;
    
    }
}

@media(max-width : 400px)
{
    .footer-container .upper-newsletter-container .newsletter-form button
    {
        padding:8px 10px;
        font-size: .9rem;
    }
}