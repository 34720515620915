.contact-form-container
{
    width: 90%;
    margin: auto;
    border-radius: 24px;
    filter: drop-shadow(0px 3px 20.5px rgba(0,0,0,0.11));
    background-image: linear-gradient(106deg, #f6eff8 0%, #ffffff 100%);
}

.contact-form-container .left-text-container
{
    color:#515151;

}

.contact-form-container .social-icons
{
    height: 40px;
    width: 40px;
    margin-right: 6px;
}

.contact-form-container .input-field
{
    border: 1px solid #B5B5B5;
    padding: 1rem 1.2rem;
    border-radius: 10px;
    background: transparent;
}
.contact-form-container .input-field:focus
{
    outline: none;
}

.contact-form-container .input-field
{
    resize: none;
}

.contact-form-container .form select
{
    appearance: none;
    cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url('../../../public/images/icons/select-arrow.svg') no-repeat;
  background-position: right 15px center;
  background-size: 10px;
}

.contact-form-container .service-from-btn
{
    border-radius: 25px;
    background: linear-gradient(88deg, #85329a 0%, #8c5ebe 100%);
    color: white;
    border: none;
    width: 100%;
    padding: 10px;
    transition: .3s;
}
.contact-form-container .service-from-btn:hover
{
    background: linear-gradient(88deg, #85329a 100%, #8c5ebe 0%);
}