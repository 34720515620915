.text-content-section
{
    background:transparent;
}

.text-content-section h2 {
  font-size: 49px; /* Adjust font size as needed */
  font-weight: bold; /* Adjust font weight as needed */
  color: #333; /* Adjust text color as needed */
  /* Add any additional styling here */
}


.text-content-section .text-content{
  /* background : rgb(235, 220, 227);
  background: radial-gradient(circle, rgb(243, 231, 236) 0%,rgb(249, 243, 246) 35%, rgba(252,252,252,1) 56%, rgba(255,255,255,1) 100%); */
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left bottom;
}

.text-content-section .container-space
{
  width: 90%;
  margin: auto;
}

.text-content-section .paddLeft
{
    padding-left: 5%;
}

.text-content-section .line-seprator
{
    border: 1px solid #c1c0c0;
    width: 100%;
    margin: auto;

}

.text-content-section .text-btn
{
  padding: 0.6rem 2.2rem;
  font-size: 14px;
  background: transparent;
  border: 1px solid #8740A5;
  color: #8740A5;
  font-weight: 700;
  font-family: "Open Sans";
  border-radius: 25px;
  text-transform: uppercase;
  transition: .3s;
}
.text-content-section .text-btn:hover
{
  background: #8740A5;
  border: 1px solid #8740A5;
  color: white;
}