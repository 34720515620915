.sponsor-container
{
    border-radius: 80px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
    background: rgb(133,50,154);
    background: linear-gradient(90deg, rgba(133,50,154,1) 0%, rgba(140,94,190,1) 100%);
}

.sponsor-container .companies-logo-box .swiper-slide 
{
    display: flex;
    align-items: center;
    justify-content: center;
}
 
.sponsor-container .companies-logo-box img{
    max-height: 60px;
}
